<template>
  <el-dialog
    v-dialogDrag
    :title="'支付履约订金: ￥' + deposit"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <el-form ref="newForm" :rules="newFormRules" :model="newForm">
      <el-row :gutter="20">
        <el-col :span="24">
          <!-- 支付履约订金：{{ deposit }}元！ -->
          <!-- <el-form-item label="履约订金" prop="deposit">
                    <el-input v-model="deposit" type="text" disabled></el-input>
                  </el-form-item> -->
        </el-col>
        <!-- <el-col :span="24">
          <el-form-item label="短信验证码" prop="code">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-row :gutter="20">
                  <el-col :span="16">
                    <el-input v-model="newForm.code"></el-input>
                  </el-col>
                  <el-col :span="8">
                    <el-button
                      class="width-100"
                      :disabled="validBtnDisabled"
                      @click="sendValidNo()"
                      >{{ validButtonText }}</el-button
                    >
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col> -->
        <el-col :span="24">
          <el-form-item label="资金密码" prop="marginPassWord">
            <el-input
              v-model="newForm.marginPassWord"
              type="password"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose()">取 消</el-button>
      <el-button type="primary" @click="confirm()" :loading="isAddLoading"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import md5 from "js-md5";
export default {
  data() {
    return {
      dialogVisible: false,
      isAddLoading: false,
      validButtonText: "发送验证码",
      validBtnDisabled: false,
      newForm: {
        code: null,
        marginPassWord: null
      },
      newFormRules: {
        // code: [
        //   { required: true, message: "请输入短信验证码", trigger: "change" }
        // ],
        marginPassWord: [
          { required: true, message: "请输入资金密码", trigger: "change" }
        ]
      },
      form: {}
    };
  },
  computed: {
    deposit() {
      let zjPriceCalc = util.floatMul(this.form.price, this.form.hangWeight);
      return util.floatMul(zjPriceCalc, this.form.marginRate);
    }
  },
  methods: {
    showDialog(row) {
      this.dialogVisible = true;
      this.getDetails(row.hangResourcesId);
    },
    getDetails(id) {
      protocolFwd.param_queryResourceDetails.param.hangResourcesId = id;
      http.postFront(protocolFwd.param_queryResourceDetails).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.form = value;
          for (let i in this.form) {
            if (!this.form[i] && this.form[i] != 0) {
              this.form[i] = "--";
            }
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.validButtonText = e + "s";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.validButtonText = "发送验证码";
        vueThis.validBtnDisabled = false;
      }
    },
    sendValidNo() {
      protocol.param_authn_margin_sendCaptcha.param.id = 30;
      http.postFront(protocol.param_authn_margin_sendCaptcha).then(response => {
        this.$EL_MESSAGE(response.data.message);
        const that = this;
        this.validBtnDisabled = true;
        this.setTime(60, that);
      });
    },
    confirm() {
      this.$refs["newForm"].validate(valid => {
        if (valid) {
          protocolFwd.param_payHangOrder.param.hangResourcesId = this.form.hangResourcesId;
          protocolFwd.param_payHangOrder.param.code = '89548';
          protocolFwd.param_payHangOrder.param.marginPassWord = md5(
            "47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=" +
              this.newForm.marginPassWord
          );
          this.isAddLoading = true;
          http
            .postFront(protocolFwd.param_payHangOrder)
            .then(response => {
              const { code, message, value } = response.data;
              this.isAddLoading = false;
              if (code == 0) {
                // this.dialogVisible = false;
                this.$EL_MESSAGE(message);
                this.$emit("reLoad");
                this.handleClose();
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(() => {
              this.isAddLoading = false;
            });
        }
      });
    },
    handleClose() {
      this.newForm = {
        code: null,
        marginPassWord: null
      };
      this.$nextTick(() => {
        this.$refs["newForm"].clearValidate();
      });
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped></style>
