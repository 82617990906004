<template>
  <el-dialog
    v-dialogDrag
    title="预售资源详情"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <div class="auditCon">
      <p class="auditNote">审核意见：{{ form.note }}</p>
    </div>
    <div class="viewDetails">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">持仓ID：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.hangHoldId }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品分类：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.className }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col
          :span="12"
          v-for="(item, index) of form.specificationsName"
          :key="index"
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品属性：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ item.label }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" v-if="form.holdManufacturer">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">生产厂家：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.holdManufacturer }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" v-if="form.placeOrigin">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">产地：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.placeOrigin }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" v-if="form.brand">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">品牌：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.brand }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">单价：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.price }}元/{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" v-if="form.quantity">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">数量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.quantity }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.hangWeight }}{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" v-if="form.tradeWay == 'P'">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">最小起订量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.minPickWeight }}{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">未摘单重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.unsettledWeight }}{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">交易商名称：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.senterpriseName }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">企业名称：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.hangEnterpriseName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">法人手机号：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.enterprisePhone }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.auditStatus | auditStatusFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">类型：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.holdType | holdTypeFilter }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">生产厂家：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.manufacturer }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">交收仓库：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.sname ? form.sname : "--" }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">业务负责人：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.businessName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">联系电话：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.businessPhone }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">预计入库时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.putWarehousTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">开票期限：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.openTicketTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">挂单有效时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.effectiveTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">交易方式：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.tradeWay == "A" ? "整批" : "分销" }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" v-if="form.tradeWay == 'P'">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">是否开启集采：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.phaseType == "T" ? "开启" : "不开" }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" v-if="form.phaseType == 'T'">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">集采重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.phaseWeight }}{{ form.unit }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" v-if="form.phaseType == 'T'">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">集采分销结束时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.phaseEndTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">是否可议价：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.bargainingStatus | isholdFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">履约订金：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ deposit }}元</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">图片：</p>
            </el-col>
            <el-col :span="16">
              <img
                class="proPictureImg"
                v-if="form.img !== '--'"
                :src="form.img"
              />
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24" v-if="priceStep.length != 0">
          <el-row :gutter="20">
            <el-col :span="4">
              <p class="title">阶梯价：</p>
            </el-col>
            <el-col :span="20">
              <span class="text" v-for="(item, index) of priceStep" :key="index"
                >{{ item.lowWeight }}< X{{ index + 1 }} ≤ {{ item.highWeight
                }}{{ form.unit }} &nbsp;{{ item.price }}元/{{
                  form.unit
                }}&nbsp;<i v-if="index < priceStep.length - 1" class="setStyle"
                  >;</i
                >&nbsp;&nbsp;</span
              >
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <!-- <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">交易商名称：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.senterpriseName }}</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row> -->

      <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">总价格：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.zjPrice }}元</p>
            </el-col>
          </el-row>
        </el-col> -->
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        // warehousId: null,
        // price: null
      },
      priceStep: []
    };
  },
  computed: {
    deposit() {
      let zjPriceCalc = util.floatMul(this.form.price, this.form.hangWeight);
      return util.floatMul(zjPriceCalc, this.form.marginRate);
    }
  },
  methods: {
    showDialog(row) {
      this.dialogVisible = true;
      this.getDetails(row.hangResourcesId, row.pickResourcesId);
      // this.getStepPrice(row.hangResourcesId)
    },
    getDetails(id, pickResourcesId) {
      let that = this;
      protocolFwd.param_queryResourceDetails.param.hangResourcesId = id;
      protocolFwd.param_queryResourceDetails.param.pickResourcesId = pickResourcesId;
      http.postFront(protocolFwd.param_queryResourceDetails).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          value.specificationsName = this.attributeTrans(
            value.specificationsName
          );
          this.form = value;
          if (value.tradeWay == "P") {
            that.getStepPrice(value.hangResourcesId);
          }
          for (let i in this.form) {
            if (!this.form[i] && this.form[i] != 0) {
              this.form[i] = "--";
            }
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getStepPrice(id) {
      protocolFwd.param_queryPhaseHangPrice.param.hangResourceId = id;
      http.postFront(protocolFwd.param_queryPhaseHangPrice).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.priceStep = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped>
.viewDetails {
  line-height: 40px;
}
.viewDetails .title {
  text-align: right;
}
.proPictureImg {
  max-width: 80%;
}
.auditCon {
  position: absolute;
  left: 20%;
  top: 20px;
}
.setStyle {
  font-style: normal;
}
</style>
